import React from "react";
import "./home.scss";
import Navbar from "../section/navbar/navbar";
import Header from "../section/header/header";
import About from "../section/about/about";
import Promo from "../section/promo/promo";
import Fasilitas from "../section/fasilitas/fasilitas.jsx";
import Rumah from "../section/rumah/rumah.jsx";
import Lokasi from "../section/lokasi/lokasi.jsx";
import Footer from "../section/footer/footer.jsx";
import tombol from "../media/tombol.webp";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement";
import Ruko from "../section/ruko/ruko.jsx";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=+6285121010886&text=Halo%20Nita,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketing-bukitpodomorojakarta.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Header />
      <About />
      <Promo />
      <Fasilitas />
      <Rumah />
      <Ruko />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
